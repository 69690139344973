<template>
  <div class="searchDiv">
    <SearchBar v-model:value="formState.keyword" :on-search="getData" >
      <template #suffix >
        <college-entry v-if="store.state.mainAppName === 'schoolPlatform'" />
      </template>
    </SearchBar>
  </div>
  <div v-show="!data.firstLoading">
    <div class="formDiv">
      <div>
        学段 ：<CommonRadio
          v-model:value="formState.gradePeriod"
          :options="formOpts.gradePeriod"
          :is-check-all="true"
          @handleClick="getData"
        />
      </div>
      <div>
        学科 ：<CommonRadio
          v-model:value="formState.subject"
          :options="formOpts.subject"
          :is-check-all="true"
          @handleClick="getData"
        />
      </div>
      <div>
        版本 ：<CommonRadio
          v-model:value="formState.pressVersion"
          :options="formOpts.pressVersion"
          :is-check-all="true"
          @handleClick="getData"
        />
      </div>
      <div>
        标签 ：<a-select
          v-model:value="formState.tags"
          mode="multiple"
          placeholder="可选择标签"
          style="width: 300px"
          :options="formOpts.tags"
          @search="handleTagSearch"
          @popupScroll="handlePopupScroll"
          @change="getData"
        />
      </div>
    </div>
    <div class="tableDiv">
      <a-tabs v-model:activeKey="type" @change="handleTypeChange">
        <a-tab-pane v-for="tabItem in tabList" :key="tabItem.key" :tab="tabItem.title" />
        <template #tabBarExtraContent>
          <div :style="{ color: store.state.themeColor, display: 'flex' }">
            <AppstoreFilled
              class="iconExtra"
              v-if="data.styleType === 2"
              @click="changeTableStyle(1)"
            />
            <UnorderedListOutlined
              class="iconExtra"
              v-if="data.styleType === 1"
              @click="changeTableStyle(2)"
            />
            <a-button
              v-permission-in-slot="PermissionMap.TEACHING_MATERIAL_CREATE"
              style="flex: 1"
              class="btn"
              type="primary"
              @click="handleOpenEdit"
              v-if="type === '1'"
            >
              创建教材
            </a-button>
            <a-button
              v-permission-in-slot="
                PermissionMap.TEACHING_MATERIAL_PERSONAL_CREATE
              "
              style="flex: 1"
              class="btn"
              type="primary"
              @click="handleOpenEdit"
              v-if="type === '2'"
            >
              创建教材
            </a-button>
          </div>
        </template>
      </a-tabs>
      <div>
        <div class="cardTableDiv" v-show="data.styleType === 1">
          <a-list
            :grid="{ gutter: 24, xs: 1, sm: 3, md: 4, lg: 4, xl: 6, xxl: 6 }"
            :data-source="dataSource"
            :loading="data.tableLoading"
            :locale="locale"
          >
            <template #renderItem="{ item }">
              <CoverBlock
                :type="type"
                :data="item"
                :src="item.poster"
                :title="item.name"
                @copy="handleCopy(item)"
                @openEdit="handleOpenEdit(item)"
                @del="handleDelete(item)"
                @jump="goToPPT(item)"
              />
            </template>
          </a-list>
        </div>
        <div class="listTableDiv" v-show="data.styleType === 2">
          <a-table
            :dataSource="dataSource"
            :columns="columns"
            rowKey="id"
            :pagination="false"
            :loading="data.tableLoading"
            :locale="locale"
          >
            <template #action="{ record }">
              <a
                @click="handleOpenEdit(record)"
                v-permission="PermissionMap.TEACHING_MATERIAL_EDIT"
                >编辑</a
              >
              <a-divider type="vertical" />
              <a
                @click="goToPPT(record)"
                v-permission="PermissionMap.TEACHING_MATERIAL_PPT"
                >查看课件</a
              >
              <a-divider
                type="vertical"
                v-permission="PermissionMap.TEACHING_MATERIAL_COPY"
              />
              <!-- <a
                @click="handleCopy(record)"
                v-permission="PermissionMap.TEACHING_MATERIAL_COPY"
                >复制到我的</a
              > -->
            </template>
            <template #tags="{ text }">
              <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
            </template>
          </a-table>
        </div>
        <div class="pagination">
          <a-pagination
            v-model:current="paginator.current"
            v-model:pageSize="paginator.pageSize"
            :total="paginator.total"
            :show-total="paginator.showTotal"
            @change="handleTableChange"
            show-quick-jumper
          />
        </div>
      </div>
    </div>
    <TeachingMaterialEdit
      :type="type"
      v-model:data="data.current"
      v-model:visible="data.modalVisible"
      @ok="handleOk"
    />
  </div>
  <a-skeleton v-if="data.firstLoading" />
  <div id="111" ref="reloadDiv" display="none"></div>
</template>

<script>
import { UnorderedListOutlined, AppstoreFilled } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { defineComponent, ref, reactive, onBeforeMount, computed, onMounted, onActivated, createVNode, watch } from 'vue'
import SearchBar from '@/components/common/SearchBar'
import { useStore } from 'vuex'
import { isFromSchoolPlatform, debounce, isFromBackEnd } from '@/utils/common'
import CoverBlock from '../components/CoverBlock'
import CommonRadio from '@/components/common/CommonRadio'
import TeachingMaterialEdit from './Edit'
import {
  getSubjectEnum,
  getGradePeriodEnum,
  getPressVersionEnum,
  getOfficialList,
  deleteOfficialTeachingMaterial,
  getPersonalList,
  deletePersonalTeachingMaterial,
  copyBook
} from '@/services/teaching-material'
import { getTags, getTeacherTags } from '@/services/common'
import { checkPermission, filterPermiison, PermissionMap } from '@/utils/auth'
import { eventCenter, event } from '@evideo/frontend-utils'
import config from '@/config'
import CollegeEntry from '@/components/common/CollegeEntry.vue'
import Empty from '@/components/common/Empty.vue'

export default defineComponent({
  name: 'TeachingMaterialList',
  components: {
    SearchBar,
    UnorderedListOutlined,
    AppstoreFilled,
    CoverBlock,
    CommonRadio,
    TeachingMaterialEdit,
    CollegeEntry
  },
  beforeRouteLeave (to, from) {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })
    console.log('preload end')
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    console.log(123, store.state)
    const type = ref('1') // 教材类型 1官方 2我的
    const paginator = reactive({
      current: 1, // 当前页
      total: 1, // 总页数
      pageSize: 12,
      showTotal: total => `共${total}条`,
      showQuickJumper: true
    })
    const data = reactive({
      current: {}, // 当前单条数据
      styleType: 1, // 表格风格 1卡片 2列表
      modalVisible: false,
      tableLoading: false,
      firstLoading: true
    })
    const tagParams = reactive({
      pageSize: 50,
      page: 1,
      keyword: ''
    })
    const dataSource = ref([])
    const columns = [
      {
        title: '教材名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '教材学科',
        dataIndex: 'subject.name',
        key: 'subject.name',
        width: 100
      },
      {
        title: '学段',
        dataIndex: 'grade_period.name',
        key: 'grade_period.name',
        width: 100
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        slots: { customRender: 'tags' },
        ellipsis: true
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        key: 'created_at'
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at',
        key: 'updated_at'
      },
      {
        title: '操作',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    // 第一次进入
    const isFirst = ref(false)
    // 检索为空
    const locale = {
      emptyText: createVNode(Empty, { value: isFirst.value })
    }

    watch(isFirst, (newValue) => {
      if (newValue === true) {
        locale.emptyText = createVNode(Empty, { value: isFirst.value })
      }
    })

    const formState = reactive({
      keyword: '',
      subject: 0,
      gradePeriod: 0,
      pressVersion: 0,
      tags: []
    })
    const formOpts = reactive({
      subject: [],
      gradePeriod: [],
      pressVersion: []
    })
    const reloadDiv = ref()

    // tab页动态渲染数组
    const tabList = filterPermiison([
      {
        key: '1',
        title: '官方教材'
      }
      // {
      //   key: '2',
      //   title: '我的教材',
      //   permission: PermissionMap.TEACHING_MATERIAL_PERSONAL_LIST
      // }
    ])
    console.log('tablist =====>   ', tabList)

    const queryParams = computed(() => {
      const params = {}
      if (formState.keyword) {
        params.name = formState.keyword
      }
      if (formState.subject) {
        params.subject_cat_id = formState.subject
      }
      if (formState.gradePeriod) {
        params.grade_period_cat_id = formState.gradePeriod
      }
      if (formState.pressVersion) {
        params.press_version_cat_id = formState.pressVersion
      }
      if (formState.tags) {
        params.tag = formState.tags
      }
      params.per_page = paginator.pageSize
      params.page = paginator.current
      return params
    })
    const getData = debounce(async (params) => {
      data.tableLoading = true
      // show_modules是k12教材筛选
      params = { ...queryParams.value, page: 1, ...params }
      if (isFromSchoolPlatform()) {
        params.show_modules = 1
      }
      const { items, pagination } = type.value === '1' ? await getOfficialList(params) : await getPersonalList(params)
      dataSource.value = items
      paginator.current = pagination.current
      paginator.total = pagination.total
      data.tableLoading = false
    }, 300, {
      leading: true,
      trailing: false
    })
    const getTagsOptions = debounce(async () => {
      const params = { per_page: tagParams.pageSize, current: tagParams.page, keyword: tagParams.keyword }
      const getTagsService = type.value === '1' ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })
    const getOptions = () => {
      getSubjectEnum().then((data) => {
        formOpts.subject = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getPressVersionEnum().then((data) => {
        formOpts.pressVersion = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getGradePeriodEnum().then((data) => {
        formOpts.gradePeriod = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getTagsOptions()
    }
    const changeTableStyle = (value) => {
      data.styleType = value
    }

    const handleOpenEdit = item => {
      data.current = item
      data.modalVisible = true
    }

    const handleOk = () => {
      data.modalVisible = false
      message.success('操作成功')
      getData({ page: paginator.current })
    }

    const handleCopy = async (record, e) => {
      const key = 'copy'
      await copyBook(record.id)
      message.loading({
        key,
        content: '正在处理'
      })
      message.success({
        key,
        content: '复制成功'
      })
    }

    const handleDelete = async item => {
      const deleteService = type.value === '1' ? deleteOfficialTeachingMaterial : deletePersonalTeachingMaterial
      await deleteService(item.id)
      message.success('删除成功')
      await getData({ page: paginator.current })
    }
    const goToPPT = item => {
      // 从卡片式的点击时，无法从标签层进行拦截
      if (checkPermission([PermissionMap.TEACHING_MATERIAL_PPT])) {
        if (isFromBackEnd()) {
          store.commit('setCurrentTeachingMaterial', item)
          router.push({
            path: `/resource/teaching-material/${item.id}/preparation/${type.value === '1' ? '0' : '1'}`
          })
        } else if (isFromSchoolPlatform()) {
          // 教师访问官方
          if (type.value === '1') {
            // 个人的访问官方，则进入预览模式
            router.push({
              path: `/resource/teaching-material/${item.id}/preview`
            })
          } else if (type.value === '2') {
            store.commit('setCurrentTeachingMaterial', item)
            router.push({
              path: `/resource/teaching-material/${item.id}/preparation/${type.value === '1' ? '0' : '1'}`
            })
          }
        }
      }
    }
    const handleTagSearch = v => {
      tagParams.page = 1
      tagParams.keyword = v
      getTagsOptions()
    }
    const handleTableChange = (page, pageSize) => {
      paginator.current = page
      paginator.pageSize = pageSize
      getData({ page: page })
    }
    const handlePopupScroll = e => {
      if (e.target.scrollTop === e.target.clientHeight) {

      }
      console.log(e)
      console.log(e.target.clientHeight)
    }

    const handleTypeChange = e => {
      getData()
    }

    onBeforeMount(async () => {
      await getOptions()
      await getData()
      data.firstLoading = false
      // 获得数据
      isFirst.value = true
    })

    const preloadPptist = async () => {
      // ppt组件预加载
      const divElm = document.createElement('div')
      divElm.style = 'display: none'
      reloadDiv.value.appendChild(divElm)
      const preloadPromise = new Promise((resolve, reject) => {
        try {
          const pptEntryUrl = config.pptist.entry
          eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
            app: config.pptist.name,
            el: divElm,
            entry: pptEntryUrl,
            args: { token: store.state.token, microAppUrl: pptEntryUrl, appName: config.pptist.name, preload: true },
            configuration: { $$cacheLifecycleByAppName: true } // TODO: $$cacheLifecycleByAppName是qiankun的一个内部实验性开关
          })
          eventCenter.sub(`${config.pptist.name}-preloadOk`, () => {
            console.log('preload is ok , unload the app!')
            eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
              app: config.pptist.name
            })
            store.commit('setHasPreloadPPT', true)
            resolve()
          })
        } catch (error) {
          reject(error)
        }
      })

      await preloadPromise
    }

    onMounted(async () => {
      const hasPreload = store.state.hasPreloadPPT
      if (hasPreload === false) {
        preloadPptist()
      }
    })

    onActivated(() => {
      getData({ page: paginator.current })
    })

    return {
      data,
      formState,
      formOpts,
      store,
      type,
      dataSource,
      paginator,
      columns,
      changeTableStyle,
      PermissionMap,
      getData,
      isFromSchoolPlatform,
      reloadDiv,
      tabList,
      handleOpenEdit,
      handleOk,
      handleCopy,
      handleDelete,
      goToPPT,
      handleTagSearch,
      handleTableChange,
      handlePopupScroll,
      handleTypeChange,
      locale
    }
  }
})
</script>

<style lang="less" scoped>
.btn {
  border-radius: 6px;
}
.searchDiv {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 22px;
  background-color: #ffffff;
}
.formDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  div {
    margin-top: 10px;
  }
}
.tableDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    flex: 1;
    //display: inline-block;
    font-size: 24px;
    margin: 4px 8px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
