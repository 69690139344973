<template>
  <div class="card">
    <div
      class="cover"
      @click="$emit('jump')"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <img :src="src" width="140" height="187" alt="" />
      <div class="hoverDiv" v-if="isHover" />
      <div class="hoverBtnDiv" v-if="isHover">
        <!-- <div>
          <a-button
            class="hoverBtn"
            type="primary"
            size="small"
            @click.stop="handleCopy"
            v-if="bookType === '1'"
            v-permission="PermissionMap.TEACHING_MATERIAL_COPY"
            >复制到我的教材</a-button
          >
        </div> -->
        <div>
          <a-button
            v-if="bookType === '1'"
            v-permission="PermissionMap.TEACHING_MATERIAL_EDIT"
            class="hoverBtn"
            type="primary"
            size="small"
            @click.stop="$emit('openEdit')"
            >编辑</a-button
          >
          <a-popconfirm
            title="确定删除吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete"
            @visibleChange="handleVisibleChange"
          >
            <a-button
              v-if="bookType === '1'"
              v-permission="PermissionMap.TEACHING_MATERIAL_DELETE"
              class="hoverBtn"
              size="small"
              @click.stop="handleDeleteVisible"
              >删除</a-button
            >
          </a-popconfirm>

          <a-button
            v-if="bookType === '2'"
            v-permission="PermissionMap.TEACHING_MATERIAL_PERSONAL_EDIT"
            class="hoverBtn"
            type="primary"
            size="small"
            @click.stop="$emit('openEdit')"
          >
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete"
            @visibleChange="handleVisibleChange"
          >
            <a-button
              v-if="bookType === '2'"
              v-permission="PermissionMap.TEACHING_MATERIAL_PERSONAL_DELETE"
              class="hoverBtn"
              size="small"
              @click.stop="handleDeleteVisible"
            >
              删除
            </a-button>
          </a-popconfirm>
        </div>
      </div>
    </div>
    <div class="title">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { ref, toRef, computed } from 'vue'
import { useStore } from 'vuex'
import { PermissionMap } from '@/utils/auth'

export default {
  name: 'CoverBlock',
  props: {
    title: String,
    src: String,
    data: Object,
    type: String,
    copy: Function,
    del: Function,
    edit: Function
  },
  setup (props, { emit }) {
    const store = useStore()
    const isHover = ref(false)
    const bookType = computed({
      get: () => props.type,
      set: v => {
        console.log(333333, v)
      }
    })
    const current = toRef(props, 'data')
    const coverLock = ref(false)
    const handleMouseEnter = () => {
      if (!coverLock.value) {
        isHover.value = true
      }
    }
    const handleMouseLeave = () => {
      if (!coverLock.value) {
        isHover.value = false
      }
    }
    const handleCopy = () => {
      emit('copy')
    }
    const handleDeleteVisible = (e) => {
      coverLock.value = true
    }
    const handleDelete = () => {
      emit('del')
      coverLock.value = false
    }
    const handleVisibleChange = (visible) => {
      if (!visible) {
        coverLock.value = false
        isHover.value = false
      }
    }
    return {
      store,
      current,
      bookType,
      isHover,
      PermissionMap,
      handleMouseEnter,
      handleMouseLeave,
      handleCopy,
      handleDelete,
      handleVisibleChange,
      handleDeleteVisible
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin: auto;
  height: 230px;
  width: 140px;
  padding-top: 12px;
  .cover {
    position: relative;
    .hoverDiv {
      bottom: 0;
      width: 100%;
      position: absolute;
      height: 131px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.62)
      );
      opacity: 0.62;
      text-align: center;
    }
    .hoverBtnDiv {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 20px;
      .hoverBtn {
        margin: 90px 3px 0 3px;
      }
    }
  }
  .title {
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: -1px;
    color: #3d4457;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>
