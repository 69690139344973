<template>
  <a-modal v-model:visible="modalVisible" :footer="null" :afterClose="handleClose" :width="620">
    <template #title>
      <div class="modalTitle" :style="{borderColor: store.state.themeColor}">
        {{ title }}
      </div>
    </template>
    <div class="content">
      <a-form ref="formRef" :rules="rules" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="right">
        <a-form-item name="name" label="教材名称">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item name="subject" label="学科" required>
          <CommonRadio v-model:value="formState.subject" :options="formOpts.subject" />
        </a-form-item>
        <a-form-item name="gradePeriod" label="学段" required>
          <CommonRadio v-model:value="formState.gradePeriod" :options="formOpts.gradePeriod" />
        </a-form-item>
        <a-form-item name="pressVersion" label="版本" required>
          <CommonRadio v-model:value="formState.pressVersion" :options="formOpts.pressVersion" />
        </a-form-item>
        <a-form-item label="标签">
          <a-select
            v-model:value="formState.tags"
            mode="multiple"
            placeholder="可选择标签"
            :options="formOpts.tags"
            @search="handleTagsChange"
          />
        </a-form-item>
        <a-form-item label="分享模式" required>
          <a-select
            v-model:value="formState.share_mode"
            :options="formOpts.share_mode"
          />
        </a-form-item>
        <a-form-item label="排序" required>
          <a-input-number :min="1" v-model:value="formState.sort" />
        </a-form-item>
        <a-form-item name="show_modules" label="展示模块" required>
          <a-select
            mode="multiple"
            v-model:value="formState.show_modules"
            :options="formOpts.show_modules"
          />
        </a-form-item>
        <a-form-item name="poster" label="教材封面">
          <a-upload
            v-model:file-list="fileList"
            name="poster"
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="formState.poster" :src="formState.poster" class="coverImg" alt="avatar" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
    <div class="footer">
      <a-button type="primary" size="large" class="submitBtn" @click="handleSubmit">
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { reactive, ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import CommonRadio from '@/components/common/CommonRadio'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { debounce } from '@/utils/common'
import {
  getGradePeriodEnum,
  getPressVersionEnum,
  getSubjectEnum,
  createOfficialTeachingMaterial,
  editOfficialTeachingMaterial,
  createTags,
  createPersonalTeachingMaterial,
  editPersonalTeachingMaterial,
  createPersonalTags
} from '@/services/teaching-material'
import { getTags, getTeacherTags } from '@/services/common'
import { getOssUploadConfig } from '@/services/oss'
import OSS from 'ali-oss'
import moment from 'moment'
import { shareModeMap } from '@/utils/dataMap'
import { moduleCodeMap } from '@/utils/dataMap/teachingMaterial'

export default {
  name: 'Edit',
  components: {
    CommonRadio,
    PlusOutlined,
    LoadingOutlined
  },
  props: {
    type: String,
    visible: Boolean,
    data: Object
  },
  setup (props, { emit }) {
    const bookType = computed(() => props.type)
    const store = useStore()
    const modalVisible = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val)
      }
    })
    const title = computed(() => props.data.id ? '编辑教材' : '创建教材')
    const formRef = ref()
    const fileList = ref([])
    const loading = ref(false)
    const ossConfig = {
      domain: null,
      client: null
    }

    const customValidator = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('请选择')
      } else {
        return Promise.resolve()
      }
    }
    const rules = {
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      subject: [{ required: true, validator: customValidator, trigger: 'blur' }],
      gradePeriod: [{ required: true, validator: customValidator, trigger: 'blur' }],
      pressVersion: [{ required: true, validator: customValidator, trigger: 'blur' }],
      poster: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      show_modules: [
        {
          required: true,
          trigger: 'change',
          validator: async (rule, value) => {
            console.log(9999, rule, value)
            if (value.length === 0) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('请选择展示模块')
            } else {
              return Promise.resolve()
            }
          }
        }
      ]
    }

    const formState = ref({
      share_mode: '',
      name: '',
      subject: 0,
      gradePeriod: 0,
      pressVersion: 0,
      tags: [],
      poster: '',
      show_modules: [],
      sort: 1
    })

    const formOpts = reactive({
      subject: [],
      gradePeriod: [],
      pressVersion: [],
      share_mode: Object.keys(shareModeMap).map(i => {
        return { value: parseInt(i), label: shareModeMap[i].name }
      }),
      show_modules: Object.keys(moduleCodeMap).map(i => {
        return { value: parseInt(i), label: moduleCodeMap[i].name }
      })
    })

    watch([() => props.data, () => props.visible], () => {
      formState.value = {
        name: props.data.name ?? '',
        subject: props.data.subject_cat_id ?? 0,
        gradePeriod: props.data.grade_period_cat_id ?? 0,
        pressVersion: props.data.press_version_cat_id ?? 0,
        tags: props.data.tags ? props.data.tags.map(i => i.tag) : [],
        poster: props.data.poster ?? '',
        share_mode: props.data.share_mode ?? 0,
        sort: props.data.sort ?? 1,
        show_modules: props.data.show_modules ?? []
      }
    })

    watch([modalVisible], () => {
      if (modalVisible.value) {
        getTagsOptions()
      }
    }, { deep: true, immediate: true })

    const handleTagsChange = value => {
      console.log(`selected ${value}`)
      getTagsOptions({ keyword: value }, { label: value, value })
    }

    const handleChange = info => {
      if (info.file.status === 'uploading') {
        loading.value = true
        return
      }

      if (info.file.status === 'done') {
        console.log('info', info)
      }

      if (info.file.status === 'error') {
        loading.value = false
        message.error('upload error')
      }
    }

    const beforeUpload = file => {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJPG) {
        message.error('请上传 jpeg、png、jpg 格式图片!')
        return false
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        // 使用ossupload覆盖默认的上传方法
        uploadToOss('/', file).then(data => {
          const url = new URL(data.res.requestUrls)
          file.url = ossConfig.domain + url.pathname
          fileList.value = [...fileList.value, file]
          formState.value.poster = file.url
        })
      }
      return false // 不调用默认的上传方法
    }

    const uploadPath = (path, file) =>
      `${path}/${moment().format('YYYYMMDD')}/${file.name.split('.')[0]}-${
        file.uid
      }.${file.type.split('/')[1]}`

    const uploadToOss = (path, file) => {
      const storeAs = uploadPath(ossConfig.basePath, file)

      return new Promise((resolve, reject) => {
        ossConfig.client
          .multipartUpload(storeAs, file)
          .then(data => {
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

    const handleSubmit = async () => {
      if (bookType.value === '1') {
        await submitOfficialBook()
      } else {
        await submitPersonalBook()
      }
    }

    const submitOfficialBook = debounce(async () => {
      formRef.value.validate().then(async () => {
        const params = {
          name: formState.value.name,
          poster: formState.value.poster,
          subject_cat_id: formState.value.subject,
          grade_period_cat_id: formState.value.gradePeriod,
          press_version_cat_id: formState.value.pressVersion,
          producer_code: 'mohuan',
          share_mode: formState.value.share_mode,
          show_modules: formState.value.show_modules,
          sort: formState.value.sort
        }
        if (!props.data.id) {
          const { id } = await createOfficialTeachingMaterial(params)
          await createTags(id, {
            tags: formState.value.tags
          })
        } else {
          await editOfficialTeachingMaterial(props.data.id, params)
          await createTags(props.data.id, {
            tags: formState.value.tags
          })
        }
        emit('ok')
      }).catch(e => {
        console.log(e)
      })
    })

    const submitPersonalBook = debounce(async () => {
      formRef.value.validate().then(async () => {
        const params = {
          name: formState.value.name,
          poster: formState.value.poster,
          subject_cat_id: formState.value.subject,
          grade_period_cat_id: formState.value.gradePeriod,
          press_version_cat_id: formState.value.pressVersion,
          show_modules: formState.value.show_modules,
          sort: formState.value.sort
        }
        if (!props.data.id) {
          const { id } = await createPersonalTeachingMaterial(params)
          await createPersonalTags(id, {
            tags: formState.value.tags
          })
        } else {
          await editPersonalTeachingMaterial(props.data.id, params)
          await createPersonalTags(props.data.id, {
            tags: formState.value.tags
          })
        }
        emit('ok')
      }).catch(e => {
        console.log(e)
      })
    })

    const getOssConfig = async () => {
      const { upload_config: data } = await getOssUploadConfig()
      ossConfig.client = new OSS({
        accessKeyId: data.access_key_id,
        accessKeySecret: data.access_key_secret,
        region: data.region,
        bucket: data.bucket,
        stsToken: data.sts_token
      })
      ossConfig.domain = data.download_domain
      ossConfig.basePath = data.base_path
    }

    const handleClose = () => {
      formRef.value.clearValidate()
    }

    const getTagsOptions = debounce(async (params, addItem) => {
      const defaultParams = { per_page: 50 }
      params = { ...defaultParams, ...params }
      const getTagsService = bookType.value === '1' ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.value = i.tag
        return i
      })
      if (formOpts.tags.length === 0 && addItem) {
        formOpts.tags.push(addItem)
      }
    })

    onBeforeMount(async () => {
      formOpts.subject = (await getSubjectEnum()).map(i => {
        i.label = i.name
        i.value = i.id
        return i
      })
      formOpts.pressVersion = (await getPressVersionEnum()).map(i => {
        i.label = i.name
        i.value = i.id
        return i
      })
      formOpts.gradePeriod = (await getGradePeriodEnum()).map(i => {
        i.label = i.name
        i.value = i.id
        return i
      })
      getTagsOptions()
      await getOssConfig()
    })

    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18
      },
      formRef,
      rules,
      formOpts,
      formState,
      title,
      store,
      modalVisible,
      fileList,
      loading,
      handleChange,
      beforeUpload,
      handleTagsChange,
      handleSubmit,
      handleClose
    }
  }
}
</script>

<style lang="less" scoped>
.modalTitle{
  margin-top: 10px;
  font-size: 20px;
  border-left: 3px solid;
  padding-left: 20px;
}
.content{
  padding: 0 18px
}
.footer{
  text-align: center;
  margin-top: 12px;
  .submitBtn{
    width: 118px;
    height: 40px;
    border-radius: 4px;
  }
}
.coverImg{
  max-height: 200px;
  width: 200px;
}
</style>
